import React from "react";
import './styles.css'
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import axios from "axios";
import staticUrl from '../DashboardData/axiosUrl.json'

export default class DynamicDashboard extends React.Component {
    state = {
        pbi_token: [],
    };

    componentDidMount() {
        axios.post(staticUrl.axiosUrl, {
            "groupId": this.props.groupId,
            "reportId": this.props.reportId
        })
            .then(res => {
                const pbi_token = res.data;
                this.setState({ pbi_token });
            })
    };

    render() {
        setInterval(() => {
            window.location.reload();
        }, (this.props.refreshTime * 1000));
        return (
            <div className="App">
                <header className="App-header">
                    <PowerBIEmbed
                        embedConfig = {{
                            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                            id: this.props.reportId,
                            embedUrl: this.props.embedUrl,
                            accessToken: this.state.pbi_token.token,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    }
                                },
                                background: models.BackgroundType.Transparent,
                            }
                        }}

                        eventHandlers = {
                            new Map([
                                ['loaded', function () {console.log('Report loaded');}],
                                ['rendered', function () {console.log('Report rendered');}],
                                ['error', function (event) {console.log(event.detail);}]
                            ])
                        }

                        cssClassName = { "report-style-class" }

                        getEmbeddedComponent = { (embeddedReport) => {
                            window.report = embeddedReport;
                        }}
                    />
                </header>
            </div>
        );
    }
}