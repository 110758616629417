import './App.css';
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import dashboards from './components/DashboardData/live-dashboard-settings.json'
import DynamicDashboard from "./components/DynamicDashboard";

export default class App extends React.Component {
    render() {
    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    {dashboards.map((dashboard) => (
                        <Route exact path={`/${dashboard.properties.customURL}`}>
                            <DynamicDashboard
                                groupId={dashboard.properties.groupId}
                                reportId={dashboard.properties.reportId}
                                embedUrl={dashboard.properties.embedUrl}
                                refreshTime={dashboard.properties.refreshTime}
                            />
                        </Route>
                    ))}
                </Switch>
            </div>
        </BrowserRouter>
        )
    }
}